import Mock from "../mock";

const database = {
  information: {
    name: 'Carrie Lee',
    aboutContent: "I'm a certified user experience analyst, usability specialist, web designer and front-end developer. I can provide everything from initial user research to iterative prototypes and final front-end code. Proper research and planning lead to a more delightful end product.",
    age: '',
    phone: '650.822.8351',
    country: 'USA',
    language: 'English',
    email: 'cal@calee.me',
    address: 'San Jose, California',
    freelanceStatus: 'September 2021',
    socialLinks: {
      facebook: '',
      twitter: '',
      pinterest: '',
      behance: '',
      linkedin: 'https://www.linkedin.com/in/carrieleedesigns/',
      dribbble: '',
      github: 'https://github.com/calee1/'
    },
    brandImage: '/images/brand-image.jpg',
    aboutImage: '/images/about-image.jpg',
    aboutImageLg: '/images/about-image-lg.jpg',
    cvfile: '/files/LeeCA-resume.pdf'
  },
  services: [
    {
      title: "Design",
      icon: 'pallet',
      details: "Product design includes all facets of UX design including interaction design, content strategy and content writing, as well as animation and graphics design."
    },
    {
      title: "Usability",
      icon: 'user',
      details: "Usability includes understanding and researching the target customer and the competitive landscape while objectively testing solutions."
    },
    {
      title: "Development",
      icon: 'code',
      details: "Front-end coding includes intermediate skills in HTML5, CSS3, JavaScript, Node.js, and React as well as SQL and XML with Agile/Scrum."
    }
  ],
  reviews: [
    {
      id: 1,
      content: "Carrie has been a pleasure to work with and a solid contribution to the team, even with swiftly changing requirements and demanding partners.",
      author: {
        name: 'Mary Henick',
        designation: 'Product owner'
      }
    },
    {
      id: 2,
      content: "Carrie has a great depth of understanding around designing UX. She cares deeply about the user's needs and works hard to make tasks easier.",
      author: {
        name: 'Steve Travis',
        designation: 'Former manager'
      }
    },
    {
      id: 3,
      content: "I would highly recommend Carrie to any team looking to get a clean expression of the user experience for their product",
      author: {
        name: 'Mitch Shiver',
        designation: 'Former manager'
      }
    }
  ],
  skills: [
    {
      title: "Figma, Sketch, InVision, Axure",
      value: 95
    },
    {
      title: "Adobe Creative Suite",
      value: 95
    },
    {
      title: "HTML5, CSS3",
      value: 90
    },
    {
      title: "JavaScript, jQuery, node.js, ReactJS",
      value: 60
    }
  ],
  portfolios: [
    {
      id: 1,
      title: "Consumer Banking",
      subtitle: "Project for new, mobile-first design pattern - a bottom drawer with a consistent call to action for cross-usage across divisions of the bank's public site starting with Home Lending. (2021)",
      imageUrl: "/images/portfolio-image-1.jpg",
      largeImageUrl: ["/images/portfolio-image-1-lg.jpg"]
    },
    {
      id: 2,
      title: "Enterprise SaaS",
      subtitle: "Project to design and test a reusable pattern library to be used across a suite of enterprise software products for email security and brand management including data visualizations. (2019)",
      imageUrl: "/images/portfolio-image-2.jpg",
      largeImageUrl: [
        "/images/portfolio-image-2-lg.jpg",
        "/images/portfolio-image-2-lg2.jpg"
      ]
    },
    {
      id: 3,
      title: "Radiology",
      subtitle: "Project for FDA-approved, web-based, radiology software. From market-based needs research to usability testing and detailed specifications for final, development and testing. (2016)",
      imageUrl: "/images/portfolio-image-3.jpg",
      largeImageUrl: ["/images/portfolio-image-3-lg.jpg"]
    },
    {
      id: 4,
      imageUrl: "/images/portfolio-image-4.png"
    }
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "Jun 2019 to Jul 2021",
        position: "Product Designer",
        company: "Wells Fargo (contractor), Oakland, CA",
        details: "Working as team lead between design and development teams, I'm responsible for facilitating solidification of design direction and delivery of visual, interaction and content specifications."
      },
      {
        id: 2,
        year: "Mar 2018 to Apr 2019",
        position: "Product/UX Designer",
        company: "Valimail, San Francisco, CA",
        details: "Worked as design lead for the initial design system and suite of products for a start-up focusing on enterprise-level, on-demand, anti-phishing, brand protection, and compliance software-as-a-service."
      },
      {
        id: 3,
        year: "Feb 2017 to Feb 2018",
        position: "Product Designer",
        company: "UrbanFootprint, Berkeley, CA",
        details: "Worked as design lead on end-to-end UX for urban planning start-up on a cloud-based, geo-spatial web application for architects, builders and city planners."
      },
      {
        id: 4,
        year: "May 2013 to Sep 2016",
        position: "Product/Interaction Designer",
        company: "Hologic, Sunnyvale, CA",
        details: "Worked as senior designer and UX team lead on end-to-end design for generative, FDA-approved medical instrumentation, and companion web-based software for radiologists. "
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "San Francisco, CA",
        graduation: "Certified User Experience Analyst",
        university: "Human Factors International",
        details: ""
      },
      {
        id: 2,
        year: "Chicago, IL",
        graduation: "Certified Usability Analyst",
        university: "Human Factors International",
        details: ""
      },
      {
        id: 3,
        year: "Chicago, IL",
        graduation: "M.S., Human-Computer Interaction",
        university: "DePaul University",
        details: ""
      },
      {
        id: 4,
        year: "Peoria, IL",
        graduation: "B.S., Communication Media",
        university: "Bradley University",
        details: ""
      }
    ]
  },
  blogs: [
    {
      id: 1,
      title: 'Markdown & Html supported blog.',
      featuredImage: '/images/blog-image-1.jpg',
      filesource: '../../blog/markdown-html-supported-blog.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 2,
      title: 'Promrammers must read books.',
      featuredImage: '/images/blog-image-6.jpg',
      filesource: '../../blog/programmers-must-read-books.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    }
  ],
  contactInfo: {
    phoneNumbers: ['650.822.8351'],
    emailAddress: ['cal@calee.me'],
    address: "San Jose, California"
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});